.modal__form {
    width: 80%;
    margin: 3rem 0;
}

.modal__form-input input {
    padding: .4rem;
}

.modal__button {
    width: 100%;
    text-align: end;
}

.modal__button button {
    width: 9rem;
}