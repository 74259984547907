.profile__container {
    display: flex;
    flex-direction: row;
    color: var(--color-light);
}

.profile__section-1 {
    width: 70%;
    transition: var(--transition);
}

.profile__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-bg-variant);
    border-radius: 0.5rem;
    box-shadow: var(--shadow);
    overflow: hidden;
    margin-bottom: 1rem;
}

.profile__card-header {
    width: 100%;
}

.profile__card-footer {
    width: 100%;
    padding: 1rem;
}

.profile__card-footer img {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border: 0.3rem solid var(--color-white);
    margin-top: -6rem;
    margin-left: 1rem;
    -webkit-box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.32);
    box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.32);;
}

.profile__card-footer__name {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: .2rem;
}

.profile__card-footer__name h5 {
    line-height: 0;
    margin-bottom: 1rem;
}

.profile__card-footer__name p {
    font-size: .9rem;
    font-weight: 100;
}

.profile__section-2 {
    margin-left: 2rem;
    color: white;
    transition: var(--transition);
    width: 30%;
}

.profile__underconstruction {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-bg-variant);
    border-radius: 0.5rem;
    box-shadow: var(--shadow);
    overflow: hidden;
    padding: 1rem;
    margin-bottom: 1rem;
}

.profile__about {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-bg-variant);
    border-radius: 0.5rem;
    box-shadow: var(--shadow);
    overflow: hidden;
    padding: 1rem;
    margin-bottom: 1rem;
}

.profile__content {
    justify-content: center;
    align-items: center;
}

.profile__content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile__licenses {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: var(--color-bg-variant);
    border-radius: 0.5rem;
    box-shadow: var(--shadow);
    padding: 1rem;
    margin-bottom: 1rem;
}

.profile__licenses-icon{
    width: 5rem;
    height: 5rem;
    flex-shrink: 0;
}

.profile__credential {
    border: 1px solid var(--color-primary);
    padding: .3rem;
    border-radius: 2rem;
}

.profile__licenses-content {
    margin-left: 1rem;
}

.profile__show-cred {
    margin-top: 1rem;
}


/* Media Query Medium devices */
@media screen and (max-width: 1024px) {
    .profile__card-footer img {
        width: 8rem;
        height: 8rem;
        margin-top: -4rem;
    }
}

/* Media Query Small devices */
@media screen and (max-width: 660px) {
    .profile__container {
        flex-direction: column;
    }
    .profile__section-1{
        width: 100%;
    }

    .profile__section-2 {
        width: 100%;
        margin-left: 0;
        margin-top: 2rem;
    }

    .profile__card-footer img {
        width: 6rem;
        height: 6rem;
        margin-top: -4rem;
    }

}

@media screen and (max-width: 344px){
    .profile__show-cred a {
        position: absolute;
        padding: 0 .3rem;
    }
}