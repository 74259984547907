.login__container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.login__form {
    width: 50%;
    padding: 4rem;
    background: var(--color-bg-variant);
    border-radius: 1.5rem;
}

.login__password {
    position: relative;
}

.login__password-icon {
    cursor: pointer;
}

.login__password svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
}
/* Large Devices */
@media screen and (max-width:1024px) {
    .login__form {
        width: var(--container-width-lg);
    }
}

/* Small devices */
@media screen and (max-width:600px) {
    .login__form {
        width: 95%;
        padding: 2rem;
    }
}

