.container.certificates__container {
    width: 40%;
    padding-bottom: 4rem;
}

.client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.certificate {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review {
    border: 1px solid var(--color-primary);
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
    width: 150px;
    border-radius: 2rem;
}

.client__review svg {
    margin-left: .3rem;
    width: 1.1rem;
    height: 2rem;
    vertical-align: middle;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.client__credential {
    background: rgba(0,0,0,0, 0.3);
    width: max-content;
    display: flex;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

/* Media Queries Medium */
@media screen and (max-width:1024px) {
    .container.certificates__container{
        width: 60%;
    }
}

/* Media Queries Small */
@media screen and (max-width:600px) {
    .container.certificates__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
    }
}