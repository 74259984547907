.modal__main {
    width: 100%;
    display: flex;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    backdrop-filter: blur(5px);

}

.modal__container {
    color: var(--color-primary);
    width: 100%;
    display: flex;
    height: 100vh;
    align-items:center;
    justify-content: center;
}

.modal__card {
    background: var(--color-bg-variant);
    width: 50%;
    overflow: hidden;
    border-radius: 1rem;
    border: 1px solid var(--color-primary);
}

.modal__close {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.modal__content-header {
    color: var(--color-white);
    background: var(--color-bg);
    border-bottom: 1px solid var(--color-primary);
}

.modal__close-title {
    width: 50%;
    position: absolute;
    text-align: center;
    padding: .4rem 0;
    cursor: default;
}

.modal__close-icon {
    cursor: pointer;
    padding: .3rem .3rem .3rem .7rem;
    border-left: 1px solid transparent;
    margin-right: .4rem;
}

.modal__close-icon:hover{
    border-left: 1px dashed white;
}

.modal__content {
    display: flex;
    justify-content: center;
}
