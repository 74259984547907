
.main {
    display: grid;
    place-items: center;
    margin:0;
    padding:0;
    height: 100%;
    width: 100%;
    
    z-index: 9999;
    position: fixed;
  }

  .main::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: -1;
    background: linear-gradient(25deg, rgba(3,15,27,1) 0%, rgba(20,59,68,1) 100%);
  }
  
  .container--loading {
    position: relative;
    animation: spin 1.4s linear infinite;
  }
  
  .ring {
    position: absolute;
    top:  50%;
    left: 50%;
    stroke-dasharray: 60 440;
    stroke-dashoffset: -500;
    stroke-width: 10px;
    animation: shift 3.2s ease-in-out infinite;
  }
  .ring:nth-child(3) {
    stroke-width: 12px;
    opacity: 0.4;
  }
  
  @keyframes shift {
    0%    {}
    50% 	{stroke-dasharray: 400 100;
           stroke-dashoffset: -350;}
    100%  {}
  }
  
  @keyframes shift-test {
    to {stroke-dashoffset: 0;}
  }
  
  @keyframes spin {
     to {
        transform: rotate(360deg);
    }
  }