.back__icon {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.back__icon svg {
    width: 50px;
    height: 50px;
    fill: var(--color-primary);
}

.back__icon:hover svg {
    fill: var(--color-white);
}